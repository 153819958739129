import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const title = 'APP Test component';
const description = 'APP application Test component!';

const TestComponent = () => {
  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta
          property="og:url"
          content="https://cra-test-v1.firefly.usermd.net"
        />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta
          property="og:image"
          content="https://source.unsplash.com/T51MXvJfYFc/600x600"
        />
      </Helmet>
      <h1>Test Component!</h1>
      <Link to="/">Back</Link>
      <br />
      <Link to="/secondary">Secondary</Link>
      <p>
        Awesome test component.
        <br />
        In og:type it should present as an article!
      </p>
    </div>
  );
};

export default TestComponent;
