import { BrowserRouter, Route, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import TestComponent from './components/TestComponent';
import SecondaryComponent from './components/SecondaryComponent';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="canonical" href="https://cra-test-v1.firefly.usermd.net" />
        <title>APP Main title</title>
        <meta name="description" content="APP application App component!" />
        <meta
          property="og:url"
          content="https://cra-test-v1.firefly.usermd.net"
        />
        <meta property="og:site_name" content="CRA Helmet test v1" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="APP Main title" />
        <meta
          property="og:description"
          content="APP application App component!"
        />
        <meta
          property="og:image"
          content="https://source.unsplash.com/dJlkMGUn9n4/600x600"
        />
      </Helmet>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>Hello!</p>
        <BrowserRouter>
          <Route
            exact
            path="/"
            component={() => (
              <>
                <Link to="/test">Go to Test Component</Link>
                <br />
                <Link to="/secondary">Go to Secondary Component</Link>
              </>
            )}
          />
          <Route exact path="/test" component={TestComponent} />
          <Route exact path="/secondary" component={SecondaryComponent} />
        </BrowserRouter>
      </header>
    </div>
  );
}

export default App;
